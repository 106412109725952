import { useColorScheme }      from "@mantine/hooks";
import dynamic                 from "next/dynamic";
import { useRouter }           from "next/router";
import { useEffect, useState } from "react";

import { useGetLoggedIn, useGetProfileData, useSetLoggedIn } from "_common/hooks/auth";
import {
	useGetDeviceTheme,
	useGetDeviceType,
	useGetIsSidebarMenuOpen, useSetDarkMode, useSetDeviceTheme,
	useSetInterestialPopup, useSetIsSidebarMenuOpen,
	useSetLoginPopup, useSetUserOnBoardingAborted
} from "_common/hooks/global";
import { isPageRoute }         from "_common/utils";
import { logoutUser }          from "_components/pages/auth/apis";
import { getUserProfilesList } from "_services/api/user";
import remoteConfigInstance    from "_services/firebase/fetchRemoteConfig";

const Presentation       = dynamic ( () => import ( "./presentation" ) );
const MobilePresentation = dynamic ( () => import ( "./mobile.presentation" ) );

const footerList = [
	{ title: "About", link: "https://pages.rooter.gg/about-us" },
	{ title: "Blogs", link: "/blogs" },
	{ title: "Terms of Service", link: "https://pages.rooter.gg/terms-of-service" },
	{ title: "Privacy Policy", link: "https://pages.rooter.gg/privacy-policy" }
];

/* const formatLiveFollowerData = ( list = [] ) => {

  return list.map ( data => ( {
    photo          : getOptImage ( data.broadcaster.sportsFan.photo ),
    name           : data.broadcaster.sportsFan.name,
    count          : data.liveViews,
    id             : data.broadcaster.sportsFan.id,
    analyticsLabel : "Profile_following"
  } ) );
}; */
/* const formatRecommendedUserData = ( list = [] ) => {
  return list.map ( data => ( {
    photo          : getOptImage ( data.authorProfilePic ),
    name           : data.authorName,
    id             : data.id,
    analyticsLabel : "Profile_recommended"
  } )
  );

};*/
/*
const formatPopularCreators = ( list = [] ) => {
  return list.map ( data => ( {
    name           : data.authorName,
    photo          : data.authorProfilePic,
    id             : data.id,
    analyticsLabel : "Profile_popular_creators"
  } ) );
}; */

/* const fetcher = async () => {
  const res = await getLiveFollowers ();

  return res.followersLive;
}; */

/* const recommendedListfetcher = async () => {
  const [ list, broadcastIds ] = await Promise.all ( [ getRecommendedList (), getRecommendedListBroadcastIds () ] );

  const data             = list?.personalizedLiveData?.recommendedStreamers;
  const broadcastIdArray = broadcastIds?.personalizedLiveData?.recommendedSessions?.sessions;

  if ( data.length !== broadcastIdArray.length )
    return null;

  const __data    = [];
  const arrLength = data.length;

  for ( let i = 0; i < arrLength; i ++ ) {
    const ele     = data[i];
    const dataObj =  {
      id               : ele?.userSportsFanId,
      isCeleb          : ele?.isCeleb,
      authorName       : ele?.name,
      isLive           : ele?.isLive,
      authorProfilePic : ele?.photo,
      description      : ele?.shortBio ? ele?.shortBio : "Follow me on Rooter ",
      postCount        : ele?.ugcStats?.postCount,
      followerCount    : shortenNumber ( ele?.followerCount ),
      broadcastId      : broadcastIdArray[i]?.id
    };

    __data.push ( dataObj );
  }

  return __data;
};*/

export const popularCreatorsFetcher = async () => {
	const remoteConfigProfiles = await remoteConfigInstance.getData ( "sportx", "top_profiles" );

	if ( !remoteConfigProfiles )
		throw "no profiles data";

	const topUserProfiles = await getUserProfilesList ( remoteConfigProfiles );

	return topUserProfiles.splice ( 0, 7 ).map ( ele => {
		return {
			id               : ele.id,
			isCeleb          : ele.isCeleb,
			isLive           : ele.isLive,
			authorName       : ele.name,
			authorProfilePic : ele.photo,
			description      : ele.shortBio ? ele.shortBio : "Follow me on Rooter",
			postCount        : ele.ugcStats.postCount,
			followerCount    : ele.follow.followers
		};
	} );
};

const SideBarMenu = () => {

	const isSidebarMenuOpen        = useGetIsSidebarMenuOpen ();
	const toggleSidebarMenu        = useSetIsSidebarMenuOpen ();
	const userProfileData          = useGetProfileData ();
	const isLoggedIn               = useGetLoggedIn ();
	const toggleDeviceTheme        = useSetDeviceTheme ();
	const isDeviceTheme            = useGetDeviceTheme ();
	const toggleDarkMode           = useSetDarkMode ();
	const setLoggedIn              = useSetLoggedIn ();
	const setUserOnBoardingAborted = useSetUserOnBoardingAborted ();
	const setInterestialPopup      = useSetInterestialPopup ();
	const setLoginPopup            = useSetLoginPopup ();
	const colorScheme              = useColorScheme ();

	const router = useRouter ();

	const [ showThemeMenuOptions, setShowThemeMenuOptions ] = useState ( false );
	const [ gameZopLinks, setGameZopLinks ]                 = useState ( {} );

	useEffect ( () => {
		if ( isSidebarMenuOpen )
			return;

		setShowThemeMenuOptions ( false );
	}, [ isSidebarMenuOpen ] );

	const checkPageRoute = page => {
		return isPageRoute ( { router, page } );
	};

	const handleClick = link => {
		router.push ( link );
	};

	const isHomePage   = checkPageRoute ( "home" );
	const isLivePage   = checkPageRoute ( "live" );
	const isStudioPage = checkPageRoute ( "studio" );
	const deviceType   = useGetDeviceType ();

	const isMobile = deviceType === "mobile";

	/* const {
    data: popularCreators
  } = useSWR ( !isLoggedIn ? "popular-creators" : null, popularCreatorsFetcher, { fallbackData: [] } ); */

	const onClickAvatar = () => {
		//
	};

	const fetchGamezopLinks = async () => {
		const links = await remoteConfigInstance.getData ( "sportx", "gamezop_web_links" );

		setGameZopLinks ( links );
	};

	useEffect ( () => {
		fetchGamezopLinks ();
	}, [] );

	const onViewProfile = () => {
		router.push ( `/profile/${ userProfileData?.id }` );
	};

	const onSelectAppearence = () => {
		setShowThemeMenuOptions ( true );
	};

	useEffect ( () => {
		if ( isDeviceTheme && colorScheme ) {
			toggleDarkMode ( colorScheme === "dark" );
		}
	}, [ colorScheme, isDeviceTheme ] );

	const themeChangeHandler = key => {
		if ( key === "device" ) {
			toggleDeviceTheme ( !isDeviceTheme );

			return;
		}

		if ( key === "light" )
			toggleDarkMode ( false );

		if ( key === "dark" )
			toggleDarkMode ( true );

		toggleDeviceTheme ( false );
	};

	const onLogout = async () => {
		try {
			await logoutUser ( { isFrom: "Header", forceLogout: false } );
			setLoggedIn ( false );
			setUserOnBoardingAborted ( false );
			toggleSidebarMenu ();
		} catch ( error ) {
			//
		}
	};

	const onClickLogin = () => {
		setLoginPopup ( true );
		toggleSidebarMenu ();
	};

	if ( isMobile ) {
		return (
			<MobilePresentation
				closeThemeMenuOptions = { () => setShowThemeMenuOptions ( false ) }
				footerList = { footerList }
				gameZopLinks = { gameZopLinks }
				handleClick = { handleClick }
				isHomePage = { isHomePage }
				isLivePage = { isLivePage }
				isLoggedIn = { isLoggedIn }
				isSidebarMenuOpen = { isSidebarMenuOpen }
				name = { userProfileData?.name }
				onClickLogin = { onClickLogin }
				onClickLogout = { onLogout }
				onSelectAppearence = { onSelectAppearence }
				onSelectTheme = { themeChangeHandler }
				onViewProfile = { onViewProfile }
				profileImage = { userProfileData?.photo }
				setInterestialPopup = { setInterestialPopup }
				showThemeMenuOptions = { showThemeMenuOptions }
				toggleSidebarMenu = { toggleSidebarMenu }
			/>
		);
	}

	return (
		<Presentation
			// isLoadingPopularCreators = { !isLoggedIn ? isValidatingPopularCreators : null }
			// isLoadingRecommendedList = { isValidatingRecommenedList }
			footerList = { footerList }
			gameZopLinks = { gameZopLinks }
			handleClick = { handleClick }
			isHomePage = { isHomePage }
			isLivePage = { isLivePage }
			// isLoggedIn = { isLoggedIn }
			isSidebarMenuOpen = { isSidebarMenuOpen }
			isStudioPage = { isStudioPage }
			// liveFollowers = { formatLiveFollowerData ( liveFollowers ) }
			onClickAvatar = { onClickAvatar }
			// popularCreators = { !isLoggedIn ? formatPopularCreators ( popularCreators ) : [] }
			// recommendedList          = { formatRecommendedUserData ( recommendedList ) }
		/>
	);
};

export default SideBarMenu;
